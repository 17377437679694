import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withTranslation } from "react-i18next";
import "../PadloveComponent.css";
import LovingStoryModalComponent from "../LovingStoryModalComponent";
import { Color } from "../../constants/Colors";

class LovingStoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  openModal = (index) => {
    this.setState({ open: true });
  };
  closeModal = () => {
    this.setState({ open: false });
  };
  addDefaultSrc(ev){
    ev.target.src = '/icons/default_profile1.png'
  }

  render() {
    const {
      timeLine,
      getDate,
      getMediaPicture,
      getPadLovePicture,
      padlovepath,
      t
    } = this.props;
    return (
      <div className="bg-white rowLovingStory">
        <div
          style={{
            flexdirection: "row",
            display: "flex", padding: "20px 10px 10px 10px"
          }}
        >
          <div
            style={{
              flex: "10",               
              display:"flex"
            }}
          >
            <div className="circle2" >
              <img
                src={
                  timeLine.owner.user_profile === undefined
                    ? "/icons/default_profile1.png"
                    : `${process.env.REACT_APP_ASSETS_ENDPOINT}medias/profile/${timeLine.owner.user_profile}`
                }
                alt=""
                style={{
                  borderRadius: "25px",
                 maxWidth:"50px",
                  objectFit: "cover",
                }}
                height={"50px"} 
                width ={"50px"} 

                onError={this.addDefaultSrc}
              />
            </div>
            <div style={{ }}>
                <div
                  style={{
                    fontSize: "16px",
                    color: Color.slate600,
                    marginTop:"4px",
                    marginLeft:"10px",
                    textAlign: "justify",
                    display:"flex",
                  }}
              >
              {timeLine.owner.padlove.yourname}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  marginLeft:"10px",
                  marginTop:"2px",
                  fontWeight:"500",
                  display:"flex",
                  textAlign: "justify",
                  color: Color.slate400,
                }}
              >
                {timeLine.owner.padlove && timeLine.owner.padlove.bridge ? timeLine.owner.padlove.bridge.name + " - " + timeLine.owner.padlove.bridge.town : ""} {" "}{timeLine.subject && timeLine.subject.category ? <div style={{color:Color.primary400}}>{" " + t("our") +" " + t(`${timeLine.subject.category}`) }</div> :""}
              </div>
           
            </div>
            
          </div>
          <div style={{flex:"1", textAlign:"end",  }}>
           <img
              src="/icons/more.png"
              alt=""
              height="auto"
              width="25"
          
            />
          </div>
        </div>
        {timeLine.subject.content != null && 
          <div
            style={{
              flex: "1",
              padding:"10px 20px"
            }}
          >
            {timeLine.subject.medias.length === 0 ? (
              <div className="textContent" style={{backgroundColor:Color.primary50,
                padding:"20px"}}>
                <div>
                  <img 
                    src="/icons/coute_end.png"
                    alt=""
                   
                    style={{rotate:"180deg"}}
                  />
                </div>
             
                <div
                  style={{
                    fontFamily: "courgette",
                    fontSize: "18px",
                    color: Color.slate600,
                    textAlign: "center",
                    
                  }}
                >
                  {timeLine.subject.content}
                </div>
                <div style={{justifyContent:"flex-end"}}>
                  <img 
                    src="/icons/coute_end.png"
                    alt=""
                   
                    style={{}}
                  />
                </div>
              </div>
            ) : timeLine.subject.content === "undefined" ? (
              " "
            ) : (
              <this.props.readMore>
                {timeLine.subject.content}
              </this.props.readMore>
            )}
          </div>
        }
        <div>
          {this.state.open === true ? (
            <LovingStoryModalComponent
              posts={this.props.timeLine}
              getMediaPicture={this.props.getMediaPicture}
              getDate={this.props.getDate}
              closeModal={this.closeModal}
              openModal={this.openModal}
              currentMedia={this.state.currentMedia}
              isOpen={this.state.open}
              ReadMore={this.props.readMore}
            />
          ) : (
            ""
          )}
          {timeLine.subject.medias.length === 0 ? (
            ""
          ) : (
            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={timeLine.subject.medias.length > 1}
              onClickItem={this.openModal}
            >
              {timeLine.subject.medias.map((image, index) => (
                <div key={index}>
                  <img
                    src={getMediaPicture(image.name)}
                    alt="Media"
                    height="300px"
                    style={{
                      margin: "auto",
                      objectFit: "cover",
                      width: "100%",
                      
                    }}
                  />
                </div>
              ))}
            </Carousel>
          )}
            <div style={{ display: "flex", padding:"15px", marginTop:"10px", justifyContent:"flex-end"}}>
              <div style={{ display: "flex",}}>
                <div className='likeCommentShareCount'>
                  {timeLine.nb_likes === 0 ? "" : timeLine.nb_likes}
                </div>
                <div style={{flex:"1"}}>
                  <img
                  src="/icons/likeheart.png"
                  alt=""
                  width={timeLine.nb_likes === 0 ? "65%" : "55%"}
                   
                  />
                </div>
              </div>
              <div style={{ display: "flex", marginLeft:"0px",}}>
            <div className='likeCommentShareCount'>
              {timeLine.nb_comments === 0 ? "" : timeLine.nb_comments}
            </div>

            <img
                src="/icons/comment.png"
                alt=""
                style={{
                  height:"24px",
                  width:"24px"
                }}    
              />
          </div>
            </div>
          
          
        </div>
      </div>
    );
  }
}

export default withTranslation()(LovingStoryComponent);
