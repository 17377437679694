import React, { Component } from "react";

import Lottie from "react-lottie";
import animationData from "./lottie/loadingHeart.json";

class LottieComponent extends Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div>
        {this.props.loading === true ? (
          <Lottie
            options={defaultOptions}
            height={60}
            width={60}
            style={{
              position: "absolute",
              top: "40%",
              left: "45%",
              color: "#ec4267",
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default LottieComponent;
