import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./HeaderComponent.css";

export class HeaderComponents extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="header-wrapper">
        <img
          className="header-bg-img"
          src="/header-bg-img.png"
          alt="header-bg-img"
        />
        <div className="header-overlay" />
        <div className="container header-body ">
          <div className="col-xs-12 col-md-9">
            <div className="header-text-parent ">
              <b className="cadenas">{t("padlove")}</b>

              <div className="immortalisez">{t("immortalize")}</div>
              <a className="padlove-wrapper page-scroll" href="#contact">
                <div className="mon-padlove">{t("hangpadlove")}</div>
              </a>
            </div>
          </div>
          <div className="  col-xs-12 col-md-3">
            <div className="phone-wrapper ">
              <img
                src="/phone-img-10.png"
                alt="phone-img"
                className="phone-img-1"
              />
              <img
                src="/mobile-icon.png"
                alt="mobile-icon"
                className="phone-1"
              />
              <img
                src="/phone-img-2.png"
                alt="phone-img"
                className="phone-img-2"
              />
              <img
                src="/mobile-icon.png"
                alt="mobile-icon"
                className="phone-2"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(HeaderComponents);
