
export const getPadloveById = async (padloveId) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}api/padlove/get/${padloveId}`;

  try {
    const padlove = await fetch(url);
    return await padlove.json();
  } catch (error) {
    return console.log(error);
  }
};

export const getOurTimeline = async (partner1Id, pageNumber, pageSize, activity) => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}api/timeline/our/v2/${partner1Id}/${pageNumber}/${pageSize}/${activity}`;

  try {
    const padloveTimeLine = await fetch(url);
    return await padloveTimeLine.json();
  } catch (error) {
    return console.log(error);
  }
};

export async function countFollowed(padlove_id) {
  const url = `${process.env.REACT_APP_API_ENDPOINT}api/follow/count/followed/${padlove_id}`
  try {
    const response = await fetch(url);
    return await response.json();
  } catch (error) {
    return console.error(error);
  }
}

export async function countFollower(padlove_id) {
  const url = `${process.env.REACT_APP_API_ENDPOINT}api/follow/count/follower/${padlove_id}`
  try {
    const response = await fetch(url);
    return await response.json();
  } catch (error) {
    return console.error(error);
  }
}

export const getLastPosts = async () => {
  const url = `${process.env.REACT_APP_API_ENDPOINT}api/timeline/activities/recents/12`;

  try {
    const lastPosts = await fetch(url);
    return await lastPosts.json();
  } catch (error) {
    return console.log(error);
  }
};

export function getLongDateToString(date) {
  var dateFomat = new Date(date).toISOString().slice(0, 10);

  return dateFomat.replace(/-/gi, ".");
}

export async function countOurHistoryReactionsByGroup(padlove_id) {
  const url = `${
    process.env.REACT_APP_API_ENDPOINT
  }api/padlove/ourhistory/countreactions/${padlove_id}`
  try {
    const response = await fetch(url);
    return await response.json();
  } catch (error) {
    return console.error(error);
  }
}

