export const Color = {
    white100: "#fff",
    colorDarkslategray_100: "#2e3a59",
    colorLightslategray: "#94a3b8",
    gray100: "#1d1d1d",
    colorPink: "#ffc8d5",
    colorSnow: "#fef7f7",
    colorAliceblue_100: "#E2E8F0",
    colorBlack: "#000",
    slate50: "#f8fafc",
    slate100: "#f1f5f9",
    slate200: "#e2e8f0",
    slate300: "#cbd5e1",
    slate400: "#94a3b8",
    slate500: "#64748b",
    slate600: "#475569",
    slate700: "#334155",
    slate800: "#1e293b",
    slate900: "#0f172a",
    slate950: "#020617",
    primary50: "#fff0f3",
    primary100: "#ffe1e7",
    primary200: "#ffc8d5",
    primary300: "#ff9bb3",
    primary400: "#ff638c",
    primary500: "#ff2c69",
    primary600: "#f60856",
    primary700: "#e50051",
    primary800: "#ae0345",
    primary900: "#940741",
    primary950: "#53001f",
    brandSecondary: "#4b4ded",
    neutralWhite: "#fff",
    neutralGrey: "#717171",
    neutralBlack: "#212121",
    successMGreen: "#009262",
    dangerMRed: "#e02b1d",
    dangerLRed: "#fff1f0",
    neutralLGrey: "#89939e",
    warningDYellow: "#e48900",
    warningLYellow: "#fff8ec",
    colorCrimson: "#e50051",
    colorLightgray: "#cbd5e1",
    primaryMBlue: "#0671e0",
  };