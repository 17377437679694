import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./FonctionnalityComponent.css";

export class FonctionnalityComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: 0,
      mobileBgImg: [
        "/design.png",
        "/map.png",
        "/description.png",
        "/evennement.png",
        "/lieux.png",
        "/retrace.png",
      ],
    };
  }
  render() {
    const { t } = this.props;
    const { isActive, mobileBgImg } = this.state;
    console.log(isActive);
    return (
      <div>
        <div className="row">
          <div
            className="heading-section col-xs-12 col-md-12"
            style={{ paddingBottom: 10 }}
          >
            <div className="tittle ">
              {t("howitworkstitle")}
              {" ?"}
            </div>
            <div className="sub-title">{t("howitworks")}</div>
          </div>
        </div>
        {/*desktop fonctionnality display*/}
        <div className="row align-items-center">
          {/*left fonctionnalities*/}
          <div className="col-md-4 col-sm-6">
            <div
              className="single-feature mb-5"
              onMouseEnter={() => this.setState({ isActive: 0 })}
            >
              <div className="feature-icon">
                <div className="icon icon-shape bg-color white-text">
                  <i className="fa fa-lock"></i>
                </div>
              </div>
              <div
                className={
                  isActive === 0 ? "feature-content active" : "feature-content"
                }
              >
                <h5>{t("personalizedesign")}</h5>
                <p className="mb-0">{t("personalizedesigndescription")}</p>
              </div>
            </div>

            <div
              className="single-feature mb-5"
              onMouseEnter={() => this.setState({ isActive: 1 })}
            >
              <div className="feature-icon">
                <div className="icon icon-shape bg-color white-text">
                  <i className="fa fa-link"></i>
                </div>
              </div>
              <div
                className={
                  isActive === 1 ? "feature-content active" : "feature-content"
                }
              >
                <h5>{t("localise")}</h5>
                <p className="mb-0">{t("localisedescription")}</p>
              </div>
            </div>

            <div
              className="single-feature mb-5"
              onMouseEnter={() => this.setState({ isActive: 2 })}
            >
              <div className="feature-icon">
                <div className="icon icon-shape bg-color white-text">
                  <i className="fa fa-share"></i>
                </div>
              </div>
              <div
                className={
                  isActive === 2 ? "feature-content active" : "feature-content"
                }
              >
                <h5>{t("history")}</h5>
                <p className="mb-0">{t("historydescription")}</p>
              </div>
            </div>
          </div>

          {/*mobile device*/}
          <div
            className="col-md-4 hidden-sm align-items-center "
            style={{ marginTop: 60 }}
          >
            <img
              className="mask-group"
              alt="mobileContent"
              src={
                isActive === 0
                  ? mobileBgImg[0]
                  : isActive === 1
                  ? mobileBgImg[1]
                  : isActive === 2
                  ? mobileBgImg[2]
                  : isActive === 3
                  ? mobileBgImg[3]
                  : isActive === 4
                  ? mobileBgImg[4]
                  : isActive === 5
                  ? mobileBgImg[5]
                  : mobileBgImg[0]
              }
            />
            <img className="mobile-device" alt="mobile" src="/mobile.png" />
            <img className="padlock1" alt="Padlock" src="/padlock1.png" />
            <img className="padlock3" alt="padlock3" src="/padlock2.png" />
          </div>

          {/*right fonctionnalities*/}
          <div className="col-md-4 col-sm-6 ">
            <div
              className="single-feature mb-5"
              onMouseEnter={() => this.setState({ isActive: 3 })}
            >
              <div className="feature-icon">
                <div className="icon icon-shape bg-color white-text">
                  <i className="fa fa-calendar"></i>
                </div>
              </div>
              <div
                className={
                  isActive === 3 ? "feature-content active" : "feature-content"
                }
              >
                <h5>{t("celebrate")}</h5>
                <p className="mb-0">{t("celebratedescription")}</p>
              </div>
            </div>

            <div
              className="single-feature mb-5"
              onMouseEnter={() => this.setState({ isActive: 4 })}
            >
              <div className="feature-icon">
                <div className="icon icon-shape bg-color white-text">
                  <i className="fa fa-comments"></i>
                </div>
              </div>
              <div
                className={
                  isActive === 4 ? "feature-content active" : "feature-content"
                }
              >
                <h5>{t("discoverromaticsites")}</h5>
                <p className="mb-0">{t("discoverromaticsitesdescription")}</p>
              </div>
            </div>

            <div
              className="single-feature mb-5"
              onMouseEnter={() => this.setState({ isActive: 5 })}
            >
              <div className="feature-icon">
                <div className="icon icon-shape bg-color white-text">
                  <i className="fa fa-heart"></i>
                </div>
              </div>
              <div
                className={
                  isActive === 5 ? "feature-content active" : "feature-content"
                }
              >
                <h5>{t("retrace")}</h5>
                <p className="mb-0">{t("retracedescription")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FonctionnalityComponent);
