import React, { Component } from "react";
import Modal from "react-modal";
import "./PadloveComponent.css";
import { Carousel } from "react-responsive-carousel";
import { AiOutlineClose } from "react-icons/ai";
import "react-responsive-carousel/lib/styles/carousel.min.css";

class LovingStoryModalComponent extends Component {
  render() {
    const { closeModal, posts, isOpen, getDate, getMediaPicture, ReadMore } =
      this.props;

    const windowWidth = window.innerWidth;
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "",
        transform: "translate(-50%, -50%)",
        width: "60%",
        height: "700px",
        backGroundColor: "white",
      },
      overlay: {
        zIndex: "9000",
      },
    };

    const customStyles1 = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "",
        transform: "translate(-50%, -50%)",
        width: "99%",
        height: "auto",
        backGroundColor: "white",
        overFlowY: "scroll",
      },
      overlay: {
        zIndex: "9000",
      },
    };

    const customStyles2 = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "",
        transform: "translate(-50%, -50%)",
        width: "90%",
        height: "700px",
        backGroundColor: "white",
      },
      overlay: {
        zIndex: "9000",
      },
    };

    return windowWidth > 925 ? (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        contentLabel="LovingStory Media Modal"
        style={customStyles}
        ariaHideApp={true}
      >
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            backgroundColor: "white",
          }}
        >
          <div style={{ height: "650px", marginTop: "1%", flex: "4" }}>
            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={posts.subject.medias.length > 1}
            >
              {posts.subject.medias.map((image, index) => (
                <img
                  key={index}
                  src={getMediaPicture(image.name)}
                  alt=""
                  height="650" 
                  style={{ objectFit: "contain" }} 
                />
              ))}
            </Carousel>
          </div>
          <div style={{ marginLeft: "15px", flex: "2" }}>
            <div className="circle2">
              <img
                src={
                  posts.owner.user_profile === undefined
                    ? "/img/default_profile.jpeg"
                    : `${process.env.REACT_APP_ASSETS_ENDPOINT}medias/profile/${posts.owner.user_profile}`
                }
                alt=""
                style={{
                  borderRadius: "25px",
                  height: "50px",
                  width: "50px",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                marginLeft: "69px",
                fontSize: "18px",
                marginTop: "-44px",
                color: "#2c3e50",
              }}
            >
              {posts.owner.padlove.yourname}
            </div>
            <div
              style={{
                fontSize: "12px",
                padding: "8px",
                fontFamily: "courgette",
                marginTop: "-10px",
                marginLeft: "62px",
                color: "#ec4267",
              }}
            >
              {getDate(posts.modifiedat)}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <ReadMore>{posts.subject.content}</ReadMore>
            </div>
          </div>
          <AiOutlineClose
            style={{
              color: "#ec4267",
              marginTop: "-1%",
              position: "relative",
              width: "22px",
              height: "45px",
            }}
            onClick={closeModal}
          />
        </div>
      </Modal>
    ) : windowWidth >= 825 && windowWidth <= 925 ? (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        contentLabel="LovingStory Media Modal"
        style={customStyles2}
        ariaHideApp={true}
      >
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            backgroundColor: "white",
          }}
        >
          <div style={{ height: "650px", marginTop: "1%", flex: "4" }}>
            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={posts.subject.medias.length > 1}
            >
              {posts.subject.medias.map((image, index) => (
                <img
                  key={index}
                  src={getMediaPicture(image.name)}
                  alt=""
                  height="650"
                  style={{ objectFit: "contain" }}
                />
              ))}
            </Carousel>
          </div>
          <div style={{ marginLeft: "15px", flex: "2" }}>
            <div className="circle2">
              <img
                src={
                  posts.owner.user_profile === undefined
                    ? "/img/default_profile.jpeg"
                    : `${process.env.REACT_APP_ASSETS_ENDPOINT}medias/profile/${posts.owner.user_profile}`
                }
                alt=""
                style={{
                  borderRadius: "25px",
                  height: "50px",
                  width: "50px",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                marginLeft: "69px",
                fontSize: "18px",
                marginTop: "-44px",
                color: "#2c3e50",
              }}
            >
              {posts.owner.padlove.yourname}
            </div>
            <div
              style={{
                fontSize: "12px",
                padding: "8px",
                fontFamily: "courgette",
                marginTop: "-10px",
                marginLeft: "62px",
                color: "#ec4267",
              }}
            >
              {getDate(posts.modifiedat)}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <ReadMore>{posts.subject.content}</ReadMore>
            </div>
          </div>
          <AiOutlineClose
            style={{
              color: "#ec4267",
              marginTop: "-1%",
              position: "relative",
              width: "22px",
              height: "45px",
            }}
            onClick={closeModal}
          />
        </div>
      </Modal>
    ) : (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        contentLabel="LovingStory Media Modal"
        style={customStyles1}
        ariaHideApp={true}
      >
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            backgroundColor: "white",
            overflowY: "scroll",
          }}
        >
          <div style={{ marginLeft: "6px", flex: "2" }}>
            <div className="circleModal">
              <img
                src={
                  posts.owner.user_profile === undefined
                    ? "/img/default_profile.jpeg"
                    : `${process.env.REACT_APP_ASSETS_ENDPOINT}medias/profile/${posts.owner.user_profile}`
                }
                alt=""
                style={{
                  borderRadius: "25px",
                  height: "50px",
                  width: "50px",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                marginLeft: "69px",
                fontSize: "18px",
                marginTop: "-44px",
                color: "#2c3e50",
              }}
            >
              {posts.owner.padlove.yourname}
            </div>
            <div
              style={{
                fontSize: "12px",
                padding: "8px",
                fontFamily: "courgette",
                marginTop: "-10px",
                marginLeft: "62px",
                color: "#ec4267",
              }}
            >
              {getDate(posts.modifiedat)}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <ReadMore>{posts.subject.content}</ReadMore>
            </div>
          </div>
          <div style={{ marginTop: "1%", flex: "3" }}>
            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={posts.subject.medias.length > 1}
            >
              {posts.subject.medias.map((image, index) => (
                <img
                  key={index}
                  src={getMediaPicture(image.name)}
                  alt="" 
                  height="100%" 
                />
              ))}
            </Carousel>
          </div>
          <AiOutlineClose
            style={{
              color: "#ec4267",
              marginLeft: "87%",
              marginTop: "-3%",
              position: "absolute",
              width: "22px",
              height: "45px",
            }}
            onClick={closeModal}
          />
        </div>
      </Modal>
    );
  }
}

export default LovingStoryModalComponent;
