import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class FooterComponent extends Component {
  render() {
    const { t } = this.props;

    return (
      <div >
        <footer className="footer-section ptb-60">
          <div className="footer-wrap">
            <div className="container">
              <div className="row">
                <div className="col-12-mb col-xs-12">
                  <div className="apropos col-md-4 col-xs-12">
                    <b className="a-propos-de">{t("about_us")}</b>
                    <div className="nous-contacter-parent">
                      <div className="nous-contacter">{t("contactus")}</div>
                      <div className="nous-contacter">{t("whoweare")} ?</div>
                      <div className="nous-contacter">FAQ</div>
                      <div className="nous-contacter">{t("pressrelease")}</div>
                    </div>
                  </div>

                  <div className="apropos col-md-4 col-xs-12">
                    <b className="a-propos-de">{t("legal")}</b>
                    <div className="nous-contacter-parent">
                      <div className="nous-contacter">
                        <a href="#terms">{t("useconditions")}</a>
                      </div>
                      <div className="nous-contacter">
                        <a href="#terms">{t("confidentiality")}</a>
                      </div>
                      <div className="nous-contacter">
                        <a href="#coo">{t("cookies")}</a>
                      </div>
                    </div>
                  </div>
                  <div className="apropos col-md-4 col-xs-12">
                    <div className="frame-parent1">
                      <div className="facebook-parent">
                        <a
                          href="https://www.facebook.com/padlove.io"
                          target="_blank" rel="noreferrer"
                        >
                          <img
                            className="facebook-icon"
                            alt=""
                            src="/img/facebook@2x.png"
                          />
                        </a>
                        <a href="https://twitter.com/Padloveio" target="_blank" rel="noreferrer">
                          <img className="x-icon" alt="" src="/img/x@2x.png" />
                        </a>
                        <a
                          href="https://www.linkedin.com/company/70467022/admin/feed/posts/"
                          target="_blank" rel="noreferrer"
                        >
                          <img
                            className="facebook-icon"
                            alt=""
                            src="/img/linkedin@2x.png"
                          />
                        </a>
                        <a
                          href="https://www.youtube.com/@padlove3492"
                          target="_blank" rel="noreferrer"
                        >
                          <img
                            className="facebook-icon"
                            alt=""
                            src="/img/youtube@2x.png"
                          />
                        </a>
                      </div>
                      <div className="frame-wrapper1">
                        <div className="image-16-parent">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.padlove"
                            target="_blank" rel="noreferrer"
                          >
                            <img
                              className="image-16-icon2"
                              alt=""
                              src="/img/image-16@2x.png"
                            />
                          </a>

                          <a
                            href="https://apps.apple.com/fr/app/padlove/id1500632026"
                            target="_blank" rel="noreferrer"
                          >
                            <img
                              className="image-17-icon1"
                              alt=""
                              src="/img/image-17@2x.png"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="copyright-section col-md-12 col-xs-12">
          <div className="copyright">
            <div className="copyright-container">
              <span className="span">{t("copyright")}</span>
              <b className="padloveio">
                <span>Padlove.io</span>
              </b>
              <span>
                <b className="padloveio">
                  <span className="span">|</span>
                </b>
                <b className="span">
                  <span>{t("allrightreserved")}</span>
                </b>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FooterComponent);
