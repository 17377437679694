import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";
import MainComponent from "./MainComponent";
import TermsAndLegacies from "./TermsAndLegacies";
import PadloveComponent from "./PadloveComponent/v2/PadloveComponent";
import ListAllBridgesComponent from "./Bridges/ListAllBridgesComponent";
import PadloveBridgeTimelineComponent from "./Bridges/PadloveBridgeTimelineComponent";
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Suspense fallback="loading">
        <Router>
          <Switch>
            <Route path="/terms">
              <TermsAndLegacies />
            </Route>
            <Route exact path="/">
              <MainComponent />
            </Route>
            <Route path="/padlove/:id">
              <PadloveComponent />
            </Route>
            <Route exact path="/bridges">
              <ListAllBridgesComponent />
            </Route>
            <Route path="/bridges/:bridgeId">
              <PadloveBridgeTimelineComponent />
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </HelmetProvider>
  );
}
export default App;
