import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./ContactComponent.css";

export class ContactComponent extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="contact">
        <div
          className="heading-section col-xs-12 col-md-12"
          style={{ paddingBottom: 10 }}
        >
          <div className="sub-title ">{t("downloadapp")}</div>
        </div>
        <div className="download-btn-contact col-md-12 col-xs-12">
          <div className="download-wrapper">
            <a
              href="https://play.google.com/store/apps/details?id=com.padlove"
              target="_blank" rel="noreferrer"
            >
              <img
                className="googleplay"
                alt=""
                src="/download-googleplay.png"
              />
            </a>
            <a
              href="https://apps.apple.com/fr/app/padlove/id1500632026"
              target="_blank" rel="noreferrer"
            >
              <img className="appstore" alt="" src="/download-appstore.png" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ContactComponent);
