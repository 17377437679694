import React, { Component } from "react";
import PadloveBridgeGrid from "./PadloveBridgeGrid";
import { getPadloveByBridges } from "../api/bridge";
import LottieComponent from "../map/lottieComponent";

const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;
const itemWidth = 50;
const itemHeight = itemWidth;
const nbColumns = Math.floor(windowWidth / itemWidth);
const bridgeHeight = windowHeight * 0.4;
const nbElements = Math.floor(bridgeHeight / itemHeight) * nbColumns;

class PadloveBridgeListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageToLoad: 0,
      padloves: [],
      page: 0,
      currentPadloves: [],
      loading: true,
    };
  }

  getPadloves = async () => {
    const { pageToLoad, padloves } = this.state;
    var nextPadloves = padloves;
    if (this.state.loading === false) return;
    const data = await getPadloveByBridges(
      this.props.bridgeId,
      pageToLoad,
      nbElements
    );
    if (!data) return;

    nextPadloves[pageToLoad] = data;
    var nextPage = data.length === nbElements ? pageToLoad + 1 : pageToLoad;

    this.setState(
      {
        padloves: nextPadloves,
        pageToLoad: nextPage,
        currentPadloves: padloves,
        page: pageToLoad,
        loading: false,
      },
      () => {
        if (data.length === nbElements) {
          this.preloadNextPage();
        }
      }
    );
  };

  preloadNextPage = async () => {
    const { pageToLoad, padloves } = this.state;
    var nextPadloves = padloves;
    const data = await getPadloveByBridges(
      this.props.bridgeId,
      pageToLoad,
      nbElements
    );
    if (!data) return;
    nextPadloves[pageToLoad] = data;
    var nextPage = data.length === nbElements ? pageToLoad + 1 : pageToLoad;
    if (nextPage === 3) return; //for test purpose
    this.setState(
      {
        padloves: nextPadloves,
        pageToLoad: nextPage,
        currentPadloves: padloves,
        page: pageToLoad,
      },
      () => {
        if (data.length === nbElements) {
          this.preloadNextPage();
        }
        return;
      }
    );
  };

  async componentDidMount() {
    this.getPadloves();
  }

  render() {
    var { padloves } = this.state;

    return (
      <div>
        <div className="horizontal-slider">
          <div className="slider-container">
            {padloves &&
              padloves.map((padlovesArray, index) => (
                <div
                  key={index}
                  className="grid"
                  style={{
                    width: windowWidth,
                    height: bridgeHeight + itemHeight,
                  }}
                >
                  <PadloveBridgeGrid
                    padloves={padlovesArray}
                    height={bridgeHeight}
                    width={windowWidth}
                    itemHeight={itemHeight}
                    itemWidth={itemWidth}
                    nbColumn={nbColumns}
                    nbElement={nbElements}
                  />
                </div>
              ))}
          </div>
        </div>
        <div style={{ position: "absolute", width: "100%", top: "160px" }}>
          <LottieComponent loading={this.state.loading} />
        </div>
      </div>
    );
  }
}

export default PadloveBridgeListComponent;
