import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./RomanticSitesComponent.css";
import { getBridges } from "../../api/bridge";

export class RomanticSitesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bridges: [],
      selectedBridges: [],
      towns: ["All"],
      currentTown: "All",
    };
  }

  getAllBridges = async () => {
    const bridges = await getBridges();
    return bridges;
  };

  componentDidMount = async () => {
    const bridges = await this.getAllBridges();
    const towns = bridges.map((b) => b.town);

    this.setState({
      bridges: bridges,
      selectedBridges: bridges,
      towns: ["All", ...towns],
      currentTown: "All",
    });
  };
  bridgeSelect() {
    var { bridges, currentTown } = this.state;

    var currentTowns =
      currentTown === "All"
        ? bridges
        : bridges.filter((p) => p.town === currentTown);
    this.setState({ selectedBridges: currentTowns, currentPlace: currentTown });
  }

  render() {
    const { towns, selectedBridges, currentTown } = this.state;
    const { t } = this.props;

    return (
      <div className="RomanticSites">
        <div
          className="heading-section col-xs-12 col-md-12"
          style={{ paddingBottom: 10 }}
        >
          <div className="tittle ">{t("towns")}</div>
          <div className="sub-title">{t("guidetoromanticsites")}</div>
        </div>
        <div className="col-md-12 col-xs-12">
          <div className="menu-section col-md-12 col-xs-12">
            {towns.map((town) => (
              <div key={town}
                onClick={() => {
                  this.setState({ currentTown: town }, () => {
                    this.bridgeSelect();
                  });
                }}
                className={currentTown === town ? "menu-1 active" : "menu-1"}
              >
                {town}
              </div>
            ))}
          </div>
          <div className="bridges-container container">
            {selectedBridges.map((bridge) => (
              <div key={bridge.name}
                style={{
                  zIndex: 10,
                  position: "relative",
                  backgroundImage: `url(${process.env.REACT_APP_ASSETS_ENDPOINT}bridges/${bridge.image} )`,
                }}
                alt="bride-img"
                className="bridge-1"
              >
                <div className="overlay"></div>
                <div
                  className="legend"
                  style={{
                    position: "absolute",
                    bottom:10,
                    zIndex: 20, 
                    margin: 20,
                    gap: 0,
                  }}
                >
                  <div className="bridge-name">{bridge.name}</div>
                  <div className="padloves-num">
                    {bridge.nb_stories} Padloves
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(RomanticSitesComponent);
