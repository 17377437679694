import React, { Component } from "react";
import { getBridges } from "../api/bridge";
import { Link } from "react-router-dom";
import "./BridgeComponent.css";
import LottieComponent from "../map/lottieComponent";
import { withTranslation } from "react-i18next";

class ListAllBridgesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { bridges: [], loading: false };
  }

  getAllBridges = async () => {
    if (this.state.loading === true) return;
    this.setState({ loading: true });
    const bridges = getBridges();
    return bridges;
  };

  componentDidMount = async () => {
    const bridges = await this.getAllBridges();
    this.setState({
      bridges: bridges,
      loading: false,
    });
  };

  render() {
    const { bridges } = this.state;
    const { t } = this.props;
    return (
      <div className="PadloveComponent bg-secondary">
        <header className="header">
          <div className="navbar navbar-default navbar-fixed-top">
            <div className="container">
              <div className="row">
                <div className="navbar-header page-scroll">
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#myNavbar"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <div style={{ flex: 1, display: "flex" }}>
                    <a className="navbar-brand page-scroll" href="/">
                      <h4 className="title">
                        <img
                          width="40px"
                          height="40px"
                          src="/padlovelogonbg.png"
                          alt="logo"
                        />
                      </h4>
                    </a>
                    <a href="/" className="previous1">
                      &#8249;
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {
          <div id="container" className="container">
            <div id="row" className="row">
              <div id="row left" className="col-md-2 bg-secondary"></div>
              <div
                id="middle row"
                className="col-md-7 bg-secondary"
                style={{ marginTop: "5px" }}
              >
                {bridges &&
                  bridges.map((bridge, index) => (
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        height: "400px",
                        backgroundColor: "white",
                        marginBottom: "10px",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          flex: "1",
                          height: "340px",
                        }}
                      >
                        <Link
                          to={{
                            pathname: `/bridges/${bridge._id}`,
                            state: { bridge },
                          }}
                        >
                          <div>
                            <img
                              src={`${process.env.REACT_APP_ASSETS_ENDPOINT}bridges/${bridge.image}`}
                              alt=""
                              height="340px"
                              width="100%"
                            />
                          </div>
                        </Link>
                      </div>
                      <div
                        style={{
                          flex: "1",
                          backgroundColor: "white",
                        }}
                      ></div>
                      <div className="bridge-name">
                        <span style={{ color: "rgb(236, 66, 103)" }}>
                          {bridge.nb_stories}
                        </span>
                        {t("padlovesOn")} {bridge.name} ({bridge.town},{" "}
                        {bridge.country})
                      </div>
                    </div>
                  ))}
              </div>
              <LottieComponent loading={this.state.loading} />
              <div id="row right" className="col-md-2 bg-secondary "></div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default withTranslation()(ListAllBridgesComponent);
