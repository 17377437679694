import React, { Component } from "react";
import "./AboutComponent.css";
import { withTranslation } from "react-i18next";

export class AboutComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImgIndex: 0,
      images: ["/about1.png", "/about2.png", "/about3.png"],
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState((prevState) => ({
        currentImgIndex:
          (prevState.currentImgIndex + 1) % this.state.images.length,
      }));
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const { t } = this.props;
    const { images, currentImgIndex } = this.state;
    return (
      <div className="principle-wrapper">
        <div className="heading-section col-xs-12 col-md-12">
          <div className="tittle ">
            {t("whatispadlove")}
            {" ?"}
          </div>
          <div className="sub-title">{t("whatispadlovesubtitle")}</div>
        </div>
        <div className="col-xs-12 col-md-6" style={{ paddingTop: 64 }}>
          <img
            src={images[currentImgIndex]}
            alt="about_img"
            className="about-img "
          />
        </div>
        <div
          className="about-description col-xs-12 col-md-6"
          style={{ paddingTop: 64 }}
        >
          <div className="ellipse-parent col-xs-4 col-md-4">
            <div
              className={
                currentImgIndex === 0 ? "ellipse-div active" : "ellipse-div"
              }
            />
            <b
              className={
                currentImgIndex === 0 ? "item-numb active" : "item-numb"
              }
            >
              1
            </b>
          </div>
          <div className="create-padlove-wrapper col-xs-8 col-md-8">
            <b className="item">{t("createyourpadlove")}</b>
            <div className="description">{t("generatepadlock")}</div>
          </div>
        </div>
        <div className="line-wrapper">
          <div className="line" />
        </div>
        <div className="about-description col-xs-12 col-md-6">
          <div className="ellipse-parent col-xs-3 col-md-4">
            <div
              className={
                currentImgIndex === 1 ? "ellipse-div active" : "ellipse-div"
              }
            />
            <b
              className={
                currentImgIndex === 1 ? "item-numb active" : "item-numb"
              }
            >
              2
            </b>
          </div>
          <div className="create-padlove-wrapper col-xs-9 col-md-8">
            <b className="item">{t("specialplaces")}</b>
            <div className="description">{t("letlovetakecontrol")}</div>
          </div>
        </div>
        <div className="line-wrapper">
          <div className="line" />
        </div>
        <div className="about-description col-xs-12 col-md-6">
          <div className="ellipse-parent col-xs-4 col-md-4">
            <div
              className={
                currentImgIndex === 2 ? "ellipse-div active" : "ellipse-div"
              }
            />
            <b
              className={
                currentImgIndex === 2 ? "item-numb active" : "item-numb"
              }
            >
              3
            </b>
          </div>
          <div className="create-padlove-wrapper col-xs-8 col-md-8">
            <b className="item">{t("sharespecialmoments")}</b>
            <div className="description">{t("postandshare")}</div>
            <div style={{ width: 100, height: 50 }}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AboutComponent);
