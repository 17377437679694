import React, { Component, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withTranslation } from "react-i18next";
import "./PadloveComponent.css";
import { getOurTimeline } from "../api/padlove";
import CreateUpdateStoryComponent from "./v2/CreateUpdateStoryComponent";
import LovingStoryComponent from "./v2/LovingStoryComponent";

const PAGE_SIZE = 10;
class padloveTimeLineComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      padlove: props.padlove,
      stories: [],
      currentPage: 0,
      stopIndex: 5,
      hasMore: true,
      loading: false,
    };
  }

  loadData = async () => {
    var { currentPage, stories, hasMore, padlove, loading } = this.state;

    if (padlove && hasMore && !loading) {
      this.setState({ loading: true }, async () => {
        var timeLine = await getOurTimeline(
          padlove.partner1._id,
          currentPage,
          PAGE_SIZE, 
          "padloveActivity"
        );
        
        var lovingStory = await getOurTimeline(
          padlove.partner1._id,
          currentPage,
          PAGE_SIZE,
          "lovingStory"
        );
        
        var { stories } = this.state;


        this.setState({
          stories: timeLine.length !== 0 ? [...stories, timeLine[0],  ...lovingStory] : [...stories, ...lovingStory] ,
          hasMore: timeLine.length !== 0,
          currentPage: currentPage + 1,
          loading: false,
        });
      });
    }
  };

  getDate = (date) => {
    if (!date) return;
    var met = `${date}`;
    var formatDate = met.split("T");
    return formatDate[0];
  };

  getPadLovePicture = (filename, size) => {
    if (!filename) return;
    if (!size) size = 500;

    const ext = filename.split(".").pop();
    const imgName = filename.replace(`.${ext}`, "");
    const newImgName = `${imgName}@s_${size}.${ext}`;

    var url = `${process.env.REACT_APP_ASSETS_ENDPOINT}padloves/${newImgName}`;
    return url;
  };

  readMore = ({ children }) => {
    if (!children) return "";
    const text = `${children}`;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    const { t } = this.props;
    return (
      <p style={{ color: "rgb(85, 76, 78)" }}>
        {isReadMore ? text.slice(0, 100) : text}
        {text.length < 100 ? (
          ""
        ) : (
          <span
            style={{
              color: "#ec4267",
              textAlign: "end",
              marginTop: "10px",
              marginLeft: "10px",
            }}
            onClick={toggleReadMore}
          >
            {isReadMore ? (
              <span>{t("more")}</span>
            ) : (
              <span>{t("showless")} </span>
            )}
          </span>
        )}
      </p>
    );
  };

  getMediaPicture = (filename, size) => {
    if (!filename) return;
    if (!size) size = 600;

    const ext = filename.split(".").pop();
    const imgName = filename.replace(`.${ext}`, "");
    const newImgName = `${imgName}@s_${size}.${ext}`;
    var url = `${process.env.REACT_APP_ASSETS_ENDPOINT}medias/${newImgName}`;

    return url;
  };

  componentDidMount = () => {
    this.loadData();
  };

  render() {
    const { t } = this.props;
    const { padlovepath } = this.props.padlove;
    const { stories, hasMore } = this.state;
    return (
      <InfiniteScroll
        dataLength={stories.length}
        next={this.loadData}
        hasMore={hasMore}
        loader={
          <p
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontFamily: "courgette",
              color: "#ec4267",
            }}
          >
            {t("loadingTimeLine")}
          </p>
        }
        // endMessage={
        //   <p
        //     style={{
        //       textAlign: "center",
        //       fontSize: "18px",
        //       fontFamily: "courgette",
        //       color: "#ec4267",
        //     }}
        //   >
        //     {t("endOfTimeLine")}
        //   </p>
        // }
        style={{ overflow: "inherit" }}
      >
        <div>
          {stories.map((timeLine, index) =>
            timeLine.objectType === "createPadlove" ||
            timeLine.objectType === "updatePadlove" ? (             
              <CreateUpdateStoryComponent
                key={index}
                getDate={this.getDate}
                getPadLovePicture={this.getPadLovePicture}
                timeLine={timeLine}
                readMore={this.readMore}
              />
            ) : timeLine.objectType === "lovingStory" ? (
              <LovingStoryComponent
                key={index}
                timeLine={timeLine}
                getDate={this.getDate}
                readMore={this.readMore}
                padlovepath={padlovepath}
                getPadLovePicture={this.getPadLovePicture}
                getMediaPicture={this.getMediaPicture}
              />
            ) : (
              ""
            )
          )}
        </div>
      </InfiniteScroll>
    );
  }
}

export default withTranslation()(padloveTimeLineComponent);
