import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "../PadloveComponent.css";
import { getPadloveById, countFollowed, countFollower, countOurHistoryReactionsByGroup,getLongDateToString } from "../../api/padlove";

import { withTranslation } from "react-i18next";
import { Color } from "../../constants/Colors";
import PadloveTimeLineComponent from "../../PadloveComponent/padloveTimeLineComponent";
import { Helmet } from "react-helmet-async";
import FooterComponent from "../../V2/FooterComponet/FooterComponent";


const reactions = ["👍", "❤️", "💝", "😍", "🥰", "🌹"];

class PadloveComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      padlove: undefined,
      followed: 0,
      follower: 0,
      loading: false,
      historyReactions: [],
    };
  }


  getPadlove = async () => {
    var padloveId = this.props.params.id;

    const padlove = padloveId ? await getPadloveById(padloveId) : null;

    return padlove;
  };

  getPadLovePicture = (filename, size) => {
    if (!filename) return;
    if (!size) size = 500;

    const ext = filename.split(".").pop();
    const imgName = filename.replace(`.${ext}`, "");
    const newImgName = `${imgName}@s_${size}.${ext}`;

    var url = `${process.env.REACT_APP_ASSETS_ENDPOINT}padloves/${newImgName}`;

    return url;
  };
  getMetaTags = ({ yourname , herhimname , padlovepath, ourhistory}) => {
   
    return (
      <Helmet defer={false}>
        
        <title>{`${yourname} & ${herhimname}`}💝</title>
        <meta property="og:title" content={`${yourname} & ${herhimname} 💝`} />
        <meta property="og:image" content={this.getPadLovePicture(padlovepath, 200)}/>
        <meta property="og:url" content={`${window.location}`} />
        <meta property="og:description" content={ourhistory} />
        <meta property="og:site_name" content="Padlove" />
        <meta property="og:type" content="article" />
        <meta name="keywords" content="padlove,love,lock,padlock,bridge love,love lock,love application"/>
        <meta http-equiv="cleartype" content="on"/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <meta name="viewport" content="width=device-width,minimum-scale=1.0,initial-scale=1,user-scalable=yes"/>
        <meta name="msapplication-TileColor" content="#e50051"/>
        <meta name="msapplication-TileImage" content="%PUBLIC_URL%/padlove_logo_ping.png"/>
        <meta name="msapplication-config" content="%PUBLIC_URL%/manifest.json"/>
        <meta name="theme-color" content="#e50051"/>
        <meta name="description" content={ourhistory}/>        
        <meta name="twitter:creator" content={"Padlove"} />
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:url" content={`${window.location}`}/>
        <meta name="twitter:title" content="Padlove"/>
        <meta name="twitter:description" content={ourhistory}/>
        <meta name="twitter:image:alt" content="image" />
        <meta name="twitter:card" content="article" />

      </Helmet>
    );
  };

  daysTogether = (datewemet) => {
    var met = new Date(datewemet).getTime();
    var today = new Date().getTime();

    var days = Math.ceil((today - met) / (1000 * 3600 * 24));

    return days;
  };
  getDate = (date) => {
    if (!date) return;
    var met = `${date}`;
    var formatDate = met.split("T");
    return formatDate[0];
  };

  getCountFollowerFollowed = async () => {
    if (this.state.loading) return;
    const padloveId = this.props.params.id;
    this.setState({ loading: true });
    const follower = padloveId ? await countFollower(padloveId) : "";
    const followed = padloveId ? await countFollowed(padloveId) : "";
    this.setState({
      followed: followed ? followed.count : 0,
      follower: follower ? follower.count : 0,
      loading: false,
    });
  };

  readMore = ({ children }) => {
    if (!children) return "";
    const text = `${children}`;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    const { t } = this.props;
    return (
      <p style={{ color: Color.slate600, textAlign:"center", padding:"10px", paddingTop:"10px" }}>
        {isReadMore ? text.slice(0, 200) : text}
        {text.length < 200 ? (
          ""
        ) : (
          <span
            style={{
              color: "#ec4267",
              textAlign: "center",
              marginTop: "10px",
              marginLeft: "10px",
            }}
            onClick={toggleReadMore}
          >
            {isReadMore ? (
              <span>{t("more")}</span>
            ) : (
              <span>{t("showless")} </span>
            )}
          </span>
        )}
      </p>
    );
  };

  getReactionsCount = (reaction) => {
    var reactions = this.state.historyReactions.filter(
      (r) => r._id === reaction
    );
    if (reactions.length) {
      return reactions[0].count < 100 ? reactions[0].count : "99+";
    } else {
      return "";
    }
  };


  async componentDidMount() {
    if (this.state.loading) return;
    this.setState({ laoding: true });
    const padlove = await this.getPadlove();

    await this.getCountFollowerFollowed();
    this.setState({ padlove, loading: false });

    var historyReactions = await countOurHistoryReactionsByGroup(padlove._id);
    if (historyReactions.length) {
      this.setState({ historyReactions: historyReactions || [] });
    }
  }

  addDefaultSrc(ev){
    ev.target.src = '/icons/default_profile1.png'
  }
  
  render() {

    var { padlove, followed, follower } = this.state;
    if (!padlove) return "";

    const {
      yourname,
      herhimname,
      partner1,
      partner2,
      nb_likes,
      datewemet,
      ourhistory,
      updateat
    } = this.state.padlove;
    const { t } = this.props;

    return (
      <div className="PadloveComponent" style={{backgroundColor:Color.slate100}}>
        {this.getMetaTags(this.state.padlove)}
        <header className="header" style={{ }}>
         
          <div className="navbar navbar-default navbar-fixed-top">
            <div className="container">
              <div className="row" >
                <div className="navbar-header page-scroll" style={{width:"100%"}}>
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#myNavbar"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <div style={{ flex: 1, display: "flex" }}>
                    <a className="navbar-brand page-scroll" href="/">
                     
                        <img
                          width="80px"
                          height="80px"
                          src="/padlovelogonbg.png"
                          alt="logo"
                        />
                        
                    </a>
                    <div className="titleWrapper" >
                          <h4 className="title">
                            {yourname} & {herhimname}
                          </h4>
                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container">
          <div className="row">
            <div
              id="column1"
              className="col-md-2 columnStyleLeft" style={{backgroundColor:Color.slate100}}></div>
            <div id="Column2" className="col-md-6 ">
              <div id="row1" className="row rowCouple">
              <img
                     height="200px" 
                    src="/img/valentineday.jpeg"
                    alt=""
                    
                    
                    style={{minWidth:"100%", rotate: "180deg",borderBottomLeftRadius:"10px", borderBottomRightRadius:"10px", objectFit:"cover"}}
                    
                  />
              </div>
              <div id="row2" className="row rowPadloveInfo" style={{ backgroundColor:Color.white100}}>
                <div className="flex-container" style={{justifyContent:"center", zIndex:100}}>
                  <div style={{ marginTop:"-20px", zIndex:100}}>
                    <img
                    height="100px"
                    width="120px"
                    src="/icons/couple.png"
                    alt=""
                    
                  /></div>
                  
                  <div style={{zIndex:100}}>
                    {partner2 ? (
                      <div className="circle1" style={{zIndex:100}} >
                        <img
                          src={
                            partner1.user_profile === undefined
                              ? "/img/default_profile.jpeg"
                              : `${process.env.REACT_APP_ASSETS_ENDPOINT}medias/profile/${partner2.user_profile}`
                          }
                          onError={this.addDefaultSrc}
                          alt=" "
                          style={{
                            borderRadius: "25px",
                            height: "45px",
                            width: "45px",
                            marginTop: "0px",
                            marginLeft: "45px",
                            objectFit: "cover",
                            zIndex:100
                            
                          }}
                        />
                      </div>
                    ) : (
                      <img
                        height="50px"
                        width="50px"
                        style={{
                          position: "absolute",
                          marginTop: "10px",
                          marginLeft: "-60px",
                          zIndex:100
                        }}
                        src="/icons/user2.png"
                        alt="volunteer"
                      />
                    )}
                  </div>
                  <div className="circle1" style={{zIndex:100}}>
                    <img
                      src={
                        partner1.user_profile === undefined
                          ? "/icons/default_profile.jpeg"
                          : `${process.env.REACT_APP_ASSETS_ENDPOINT}medias/profile/${partner1.user_profile}`
                      }
                      onError={this.addDefaultSrc}
                      alt=" "
                      style={{
                        borderRadius: "25px",
                        height: "45px",
                        width: "45px",
                        marginLeft: "0px",
                        marginTop: "-30px",
                        objectFit: "cover",
                        zIndex:100
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                     display: "flex",
                   
                  }}
                >
                  <div className="yourName">{yourname}</div>
                 
                  <div className="himHerName">{herhimname}</div>
                </div>

                <div className="daysTogetherText" style={{ color:Color.slate600}}>
                    {this.daysTogether(datewemet)
                      ? this.daysTogether(datewemet)
                      : 0}{" "}{t("daystogether")}
                </div>

                <div className="flex-container" style={{justifyContent:"center", marginTop: "-10px"}}>
                  <div style={{color:Color.slate500, display:"flex", flexDirection:"row"}}>
                    <div className="likesText">
                    <p className="likes" style={{color:Color.slate800}}>{nb_likes}</p>
                    {t("like")}{nb_likes > 0  ? "s":""}
                  </div>
                  <div className="followersText" style={{}}>
                  <p className="likes" style={{color:Color.slate800, borderLeftWidth:0.5, borderRightWidth:0.5, borderLeftStyle:"inset", borderRightStyle:"inset", borderRightColor:Color.slate300,marginLeft:"-10px",marginRight:"-10px", borderLeftColor:Color.slate50}}>{follower}</p>
                     {t("follower")}{follower > 0  ? "s":""}
                  </div>
                  <div className="followsText">
                  <p className="likes" style={{color:Color.slate800}}>{followed}</p>
                     {t("follow")}{followed > 0  ? "s":""}
                  </div></div>
                  
                </div>

                <div className="rowOurHistory" style={{justifyContent:"center", alignItems:"center"}}>
                  <div
                    style={{
                      fontFamily: "courgette",
                      fontWeight: "600",
                      color: Color.slate700,
                      display:"flex",
                      justifyContent:"center",
                    }}
                  >
                    {t("ourhistory")}
                    <img
                    height="25px"
                    width="25px"
                    alt=""
                    src="/icons/filledheart.png"
                    style={{marginLeft:"20px"}}
                  />
                  <div style={{fontWeight: "400",color: Color.slate600, marginLeft:"30px"}}>{getLongDateToString(updateat)}</div>
                  </div>
                  
                  <this.readMore>{ourhistory}</this.readMore>
                  <div style={{textAlign:"center"}}>
                    <img
                      height="1px"
                      width="250px"
                      src="/img/brannanBlowout.png"
                      alt="brannanBlowout"
                    />
                  
                  <p>
                    {ourhistory
                      ? reactions.map((e, index) => {
                        
                          return (
                            <span key={index} style={{  color:Color.slate700, fontWeight:"600", padding:"10px" }}>
                               {this.getReactionsCount(e)}{" "}
                              {e}
                            </span>
                          );
                        })
                      : ""}
                  </p></div>
                </div>
              </div>
              
              <div className="image-16-parent">
                <a
                  href="https://play.google.com/store/apps/details?id=com.padlove"
                  target="_blank" rel="noreferrer"
                >
                  <img
                    className="image-16-icon"
                    alt=""
                    src="/img/image-16@2x.png"
                  />
                </a>

                <a
                  href="https://apps.apple.com/fr/app/padlove/id1500632026"
                  target="_blank" rel="noreferrer"
                >
                  <img
                    className="image-16-icon"
                    alt=""
                    src="/img/image-17@2x.png"
                  />
                </a>
              </div>
               
              <PadloveTimeLineComponent padlove={this.state.padlove} />
            </div>

            <div
              id="Column3"
              className="col-md-3 olumnStyleRight " style={{backgroundColor:Color.slate100}}
            ></div>
          </div>
        </div>
        <section id="foooter">
          <FooterComponent />
        </section>
      </div>
    );
  }
}

export default withTranslation()((props) => (
  <PadloveComponent {...props} params={useParams()} />
));
