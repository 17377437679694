import React, { Component } from "react";
import { useLocation } from "react-router-dom";
//import { withTranslation } from "react-i18next";
import "./PadloveBridgeTimelineComponent.css";
import PadloveBridgeListComponent from "./PadloveBridgeListComponent";

class PadloveBridgeTimelineComponent extends Component {
  getBridgeCover() {
    const { image } = this.props.location.state.bridge;
    const url = `${process.env.REACT_APP_ASSETS_ENDPOINT}bridges/${image}`;
    return url;
  }
  render() {
    const { _id } = this.props.location.state.bridge;

    return (
      <div>
        <header className="header">
          <div className="navbar navbar-default navbar-fixed-top">
            <div className="container">
              <div className="row">
                <div className="navbar-header page-scroll">
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#myNavbar"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <div style={{ flex: 1, display: "flex" }}>
                    <a className="navbar-brand page-scroll" href="/">
                      <h4 className="title">
                        <img
                          width="40px"
                          height="40px"
                          src="/padlovelogonbg.png"
                          alt="logo"
                        />
                      </h4>
                    </a>
                    <a href="/bridges" className="previous">
                      &#8249;
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div
          style={{
            backgroundImage: `url(${this.getBridgeCover()})`,
            height: "700px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            style={{
              paddingLeft: "60px",
            }}
          >
            <div
              style={{
                fontSize: "50px",
                fontWeight: "900px",
                paddingTop: "70px",
                color: "white",
                fontFamily: "courgette",
              }}
            >
              {this.props.location.state.bridge.town},
              {this.props.location.state.bridge.country}
            </div>
            <div
              style={{
                paddingTop: "40px",
                fontSize: "20px",
                color: "white",
              }}
            >
              {this.props.location.state.bridge.nb_stories}{" "}
              {/*t("padlovesSur")*/}
            </div>
            <div
              style={{
                paddingTop: "10px",
                fontSize: "35px",
                color: "white",
                fontWeight: "lighter",
              }}
            >
              {this.props.location.state.bridge.name}
            </div>
          </div>
          <div style={{ paddingTop: "-5%" }} className="col-md-12">
            <PadloveBridgeListComponent bridgeId={_id} />
          </div>
        </div>
      </div>
    );
  }
}
export default (props) => (
  <PadloveBridgeTimelineComponent {...props} location={useLocation()} />
);
