import React, { Component, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withTranslation } from "react-i18next";
import "../PadloveComponent/PadloveComponent.css";
import { getLastPosts } from "../api/padlove";
import "../MainComponent.css";
import "./RecentPost.css";
import LovingStoryComponent from "../PadloveComponent/v2/LovingStoryComponent";

class RecentPosts extends Component {
  constructor(props) {
    super(props);
    this.state = { LastPost: [] };
  }

  getTenLastPosts = async () => {
    const LastPosts = await getLastPosts();
    return await LastPosts;
  };
  getDate = (date) => {
    if (!date) return;
    var met = `${date}`;
    var formatDate = met.split("T");
    return formatDate[0];
  };

  getPadLovePicture = (filename, size) => {
    if (!filename) return;
    if (!size) size = 500;

    const ext = filename.split(".").pop();
    const imgName = filename.replace(`.${ext}`, "");
    const newImgName = `${imgName}@s_${size}.${ext}`;

    var url = `${process.env.REACT_APP_ASSETS_ENDPOINT}padloves/${newImgName}`;
    return url;
  };

  readMore = ({ children }) => {
    if (!children) return "";
    const text = `${children}`;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    const { t } = this.props;
    return (
      <p style={{ color: "rgb(85, 76, 78)" }}>
        {isReadMore ? text.slice(0, 100) : text}
        {text.length < 100 ? (
          ""
        ) : (
          <span
            style={{
              color: "#ec4267",
              textAlign: "end",
              marginTop: "10px",
              marginLeft: "10px",
            }}
            onClick={toggleReadMore}
          >
            {isReadMore ? (
              <span>{t("more")}</span>
            ) : (
              <span>{t("showless")} </span>
            )}
          </span>
        )}
      </p>
    );
  };

  getMediaPicture = (filename, size) => {
    if (!filename) return;
    if (!size) size = 600;

    const ext = filename.split(".").pop();
    const imgName = filename.replace(`.${ext}`, "");
    const newImgName = `${imgName}@s_${size}.${ext}`;

    var url = `${process.env.REACT_APP_ASSETS_ENDPOINT}medias/${newImgName}`;

    return url;
  };

  async componentDidMount() {
    const LastPost = await this.getTenLastPosts();
    return this.setState({
      LastPost,
    });
  }

  render() {
    const { LastPost } = this.state;
    return (
      <div className=" grid-container">
 
          {LastPost &&
            LastPost.map((post, index) => (
              <div className="grid-item"  key={index}>
                <a href={`/padlove/${post.owner.padlove._id}`}>
                <LovingStoryComponent
                 
                  timeLine={post}
                  getDate={this.getDate}
                  readMore={this.readMore}
                  padlovepath={post.owner.padlove.padlovepath}
                  getPadLovePicture={this.getPadLovePicture}
                  getMediaPicture={this.getMediaPicture}
                />
                </a>
              </div>
            ))} 
      </div>
    );
  }
}

export default withTranslation()(RecentPosts);
