import React from "react";

import { withTranslation } from "react-i18next";
class HeaderComponent extends React.Component {
  state = {
    liColor: "#ffffff",
    flag: "false",
  };

  listenScrollEvent = (e) => {
    if (window.scrollY > 85) {
      this.setState({ liColor: "#263047" });
      this.setState({ flag: "true" });
    } else {
      this.setState({ liColor: "#ffffff" });
      this.setState({ flag: "false" });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  render() {
    const { t } = this.props;
    console.log(this.state.flag);
    return (
      <header className="header">
        <div className="navbar navbar-default navbar-fixed-top ">
          <div className="container">
            <div className="row">
              <div className="navbar-header page-scroll">
                <button
                  type="button"
                  className={
                    this.state.flag
                      ? "navbar-toggle collapsed "
                      : "navbar-toggle collapsed scroll"
                  }
                  data-toggle="collapse"
                  data-target="#myNavbar"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span
                    className={
                      this.state.flag ? "icon-bar" : "icon-bar  scroll"
                    }
                  ></span>
                  <span
                    className={
                      this.state.flag ? "icon-bar" : "icon-bar  scroll"
                    }
                  ></span>
                  <span
                    className={
                      this.state.flag ? "icon-bar" : "icon-bar  scroll"
                    }
                  ></span>
                </button>
                <a
                  className="navbar-brand page-scroll"
                  style={{
                    display: "block",
                  }}
                  href=""
                >
                  <div id="padloveLogo"></div>
                </a>
              </div>
              {/* active mobile view*/}

              <div className="navbar-collapse collapse" id="myNavbar">
                <ul className="nav navbar-nav navbar-left header-nav">
                  <li className="active">
                    <a className="page-scroll" href="#header">
                      {t("home")}
                    </a>
                  </li>
                  <li>
                    <a className="page-scroll" href="#about">
                      {t("about")}
                    </a>
                  </li>
                  <li>
                    <a className="page-scroll" href="#fonctionnality">
                      {t("features")}
                    </a>
                  </li>
                  <li>
                    <a className="page-scroll" href="#sites">
                      {t("Villes")}
                    </a>
                  </li>
                  <li>
                    <a className="page-scroll" href="#testimonials">
                      {t("RecentPosts")}
                    </a>
                  </li>
                </ul>
                <ul className="nav navbar-nav navbar-right header-nav">
                  <li>
                    <a className="page-scroll" href="#footer">
                      {t("FAQ")}
                    </a>
                  </li>
                  <li>
                    <a className="page-scroll" href="#contact">
                      {t("contact")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withTranslation()(HeaderComponent);
