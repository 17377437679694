import React from "react";
import HeaderComponent from "./HeaderComponent";
import { withTranslation } from "react-i18next";
import "./MainComponent.css";
import { Helmet } from "react-helmet-async";
import HeaderComponents from "./V2/HeaderComponent/HeaderComponents";
import AboutComponent from "./V2/AboutComponent/AboutComponent";
import FonctionnalityComponent from "./V2/FonctionnalityComponent/FonctionnalityComponent";
import RomanticSitesComponent from "./V2/RomanticSitesComponent/RomanticSitesComponent";
import ContactComponent from "./V2/ContactCoponent/ContactComponent";
import RecentPosts from "./RecentPostComponent/RecentPosts";
import FooterComponent from "./V2/FooterComponet/FooterComponent";

class MainComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getMetaTags = () => {
    const { t } = this.props;
    return (
      <Helmet>
        <title>Padlove</title>
        <meta property="og:title" content="Padlove" />
        <meta property="og:image" content="%PUBLIC_URL%/padlove_logo_ping.png" />
        <meta property="og:description" content={t("slogan")}/> 
        <meta property="og:url" content="%PUBLIC_URL%"/>
        <meta property="og:site_name" content="Padlove"/>
        <meta name="keywords" content="padlove,love,lock,padlock,bridge love,love lock,love application"/>
        <meta http-equiv="cleartype" content="on"/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <meta name="viewport" content="width=device-width,minimum-scale=1.0,initial-scale=1,user-scalable=yes"/>      
        <meta name="msapplication-TileColor" content="#e50051"/>
        <meta name="msapplication-TileImage" content="%PUBLIC_URL%/padlove_logo_ping.png"/>
        <meta name="msapplication-config" content="%PUBLIC_URL%/manifest.json"/>
        <meta name="theme-color" content="#e50051"/>
        <meta name="description" content={t("slogan")}/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:url" content="%PUBLIC_URL%"/>
        <meta name="twitter:title" content="Padlove"/>
        <meta name="twitter:description" content={t("slogan")}/>
      </Helmet>
    );
  };
  render() {
    const { t } = this.props;
    return (
      <div>
        {this.getMetaTags()}
        <HeaderComponent />
        <section id="header" className=" section-hero ">
          <div className="container-fluid">
            <div className="row no-gutters">
              <div className="hero-content-wrap">
                <HeaderComponents />
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="bg-secondary ptb-40">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <AboutComponent />
              </div>
            </div>
          </div>
        </section>
        <section id="fonctionnality" className="bg-white ptb-40">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <FonctionnalityComponent />
              </div>
            </div>
          </div>
        </section>
        <section id="sites" className="bg-secondary" style={{}}>
          <div className="romantic-sites">
            <RomanticSitesComponent />
          </div>
        </section>

        <section id="testimonials">
          <div className="testimonials">
            <div
              className="heading-section-rp col-xs-12 col-md-12"
              style={{ paddingBottom: 50 }}
            >
              <div className="tittle ">{t("RecentPosts")}</div>
              <div className="sub-title">{t("recentpostsubtitle")}</div>
            </div>
            <RecentPosts />
          </div>
        </section>

        <section id="contact" className="contact-us bg-white ptb-40">
          <div className="contact-us-wrap">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <ContactComponent />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="foooter">
          <FooterComponent />
        </section>
      </div>
    );
  }
}
export default withTranslation()(MainComponent);
