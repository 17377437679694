import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withTranslation } from "react-i18next";
import "../PadloveComponent.css";
import { Color } from "../../constants/Colors";

class CreateUpdateComponent extends Component {
  state = {};

  addDefaultSrc(ev){
    ev.target.src = '/icons/default_profile1.png'
  }
  
  render() {
    const { timeLine, getPadLovePicture, getDate, t } = this.props;
    return (
      <div className='row bg-white rowLovingStory'>
        <div style={{
            display: "flex",
          }} 
        >
          <div
            style={{
                flex:"1",
                fontSize: "12px",
                fontFamily: "courgette",
                color: Color.primary700,
                padding:"15px"
            }}
          >
            {getDate(timeLine.modifiedat)}
          </div>
          {/* <div style={{flex:"1", textAlign:"end", paddingRight:"20px"}}>
           <img
              src="/icons/more.png"
              alt=""
              height="25"
              width="25"
          
            />
          </div> */}
        </div>
       
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            marginLeft:"20px",
            marginRight:"20px"
          }}
        >
          <div style={{ flex: "1", textAlign:"start" }}>
            <div className='circle2' style={{display:"flex"}}>
              <img
                src={
                  timeLine.owner.user_profile === undefined
                    ? "/icons/default_profile1.png"
                    : `${process.env.REACT_APP_ASSETS_ENDPOINT}medias/profile/${timeLine.owner.user_profile}`
                }
                onError={this.addDefaultSrc}
                alt=' '
                style={{
                  borderRadius: "25px",
                  
                  maxWidth: "50px",
                  objectFit: "cover",
                }}

                height={"50px"}
                width= {"50px"}
              />
             
              <div
                style={{
                  fontSize: "18px",
                  color: Color.slate600,
                  marginLeft:"10px",
                  alignSelf:"center"
                }}
              >
                {timeLine.subject.yourname}
              </div>
            </div>
          </div>
          <div
            style={{
              flex: "1",
              textAlign:"center",
              paddingTop:"10px"
            }}
          >
            <h6
              style={{
                fontSize: "15px",
                fontFamily: "courgette",
                color: Color.primary700,
              }}
            >
              {t("and")}
            </h6>
            <div
              style={{
                fontSize: "15px",
                justifyContent: "space-between",
                alignItems: "center",
                color:Color.slate500,
                marginBottom:"10px"
              }}
            >
              {timeLine.objectType === "createPadlove" ? (
                <p>{t("createdtheirpadlove")}</p>
              ) : (
                <p>{t("updatedtheirpadlove")}</p>
              )}
            </div>
          </div>
          <div style={{ flex:"1", display:"flex", justifyContent:"flex-end", paddingRight:"10px" }}>
            <div
              style={{
                fontSize: "18px",
                  color: Color.slate600,
                  marginRight:"10px",
                  paddingTop:"10px",
              }}
            >
              {timeLine.subject.herhimname}
            </div>
            <div>
              {timeLine.subject.partner2 ? (
                <div className='circle3'>
                  <img
                    src={
                      timeLine.subject.partner2.user_profile === undefined
                        ? "/icons/default_profile1.png"
                        : `${process.env.REACT_APP_ASSETS_ENDPOINT}medias/profile/${timeLine.subject.partner2.user_profile}`
                    }
                    onError={this.addDefaultSrc}
                    alt=' '
                    style={{
                      borderRadius: "25px",
                      
                      maxWidth: "50px",
                      objectFit: "cover",
                    }}
    
                    height={"50px"}
                    width= {"50px"}
                  />
                </div>
              ) : (
                <img
                  height='45px'
                  width="45px"
                  src='/icons/user2.png'
                  alt='volunteer'
                  style={{
                    marginLeft: "auto",
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            paddingTop:"10px",
            paddingBottom:"10px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={getPadLovePicture(timeLine.padlovepath, 500)}
            alt=''
            width='200px'
            style={{
              margin: "auto",
              display: "flex",
            }}
          />
        </div>
        <div>
        
        </div>
        <div
          style={{
            flexDirection: "row",
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent:"flex-end"
          }}
        >
          
          <div>
            <div style={{ display: "flex",}}>
              <div className='likeCommentShareCount'>
                {timeLine.nb_likes === 0 ? "" : timeLine.nb_likes}
              </div>
              <div style={{flex:"1"}}>
                <img
                src="/icons/likeheart.png"
                alt=""
                width={timeLine.nb_likes === 0 ? "65%" : "55%"}
                style={{
                
                }}    
              /></div>
              
            </div>
          </div>
          <div style={{ display: "flex", marginTop:"-4px", marginLeft:"-5px"}}>
            <div className='likeCommentShareCount'>
              {timeLine.nb_comments === 0 ? "" : timeLine.nb_comments}
            </div>

            <img
                src="/icons/comment.png"
                alt=""
                style={{
                  height:"24px",
                  width:"24px"
                }}    
              />
          </div>
          <div style={{ display: "flex", marginLeft:"10px"}}>
            <div className='likeCommentShareCount'>
              {timeLine.nb_shares === 0 ? "" : timeLine.nb_shares}
            </div>
            <div>
            <img
                src="/icons/export.png"
                alt=""
                style={{
                  height:"23px",
                  width:"23px"
                }}    
              />
           </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CreateUpdateComponent);
