import React, { Component } from "react";
import "./PadloveBridgeGrid.css";
import { Link } from "react-router-dom";

class PadloveBridgeGrid extends Component {
  constructor(props) {
    super(props);
    this.state = { padloves: props.padloves };
  }

  render() {
    var { padloves } = this.state;
    return (
      <div style={{ display: "table-row" }}>
        <div
          className="gridcontainer"
          style={{
            height: this.props.height + 40,
            width: this.props.width + 4,
          }}
        >
          {padloves &&
            padloves.map((padlove, index) => (
              <Link
                to={`/padlove/${padlove._id}`}
                key={index}
                style={{
                  width: this.props.itemWidth,
                  margin: "0px",
                }}
                className="crossed"
              >
                <img
                  src={this.getPadLovePicture(padlove.padlovepath, 50)}
                  alt=""
                />
              </Link>
            ))}
        </div>
      </div>
    );
  }

  getPadLovePicture = (filename, size) => {
    if (!filename) return;
    if (!size) size = 50;

    const ext = filename.split(".").pop();
    const imgName = filename.replace(`.${ext}`, "");
    const newImgName = `${imgName}@s_${size}.${ext}`;

    var url = `${process.env.REACT_APP_ASSETS_ENDPOINT}padloves/${newImgName}`;

    return url;
  };
}

export default PadloveBridgeGrid;
