export const getBridges = async () => {
  try {
    const url = `${process.env.REACT_APP_API_ENDPOINT}api/bridge/getbridges`;
    const result = await fetch(url);
    return await result.json();
  } catch (error) {
    console.log(error);
  }
};

export async function getPadloveByBridges(bridge_id, page, size) { // open api
  const url = `${process.env.REACT_APP_API_ENDPOINT}api/bridge/getpadloves/${bridge_id}/${page}/${size}?token=eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI2MTNjYjY4NDU4MTBlYTAwMWNmNTEwNjIiLCJleHBpcmVzSW4iOiIxZCJ9.2UrHnqFEt4rFspRullnWtqFTGvwXS9pm29tacVlDCEoLX1n6UrDcS4owD8nTG0yAULK199H9U6X9oSdohCq9_4sk9Ljll2ixxTUWNyIEy2PpKLl5PSSULU0WOyKJgyrtMlCvLwfWKlWLoTpezsJMup6dD1RfQVJ_qXaZkrGqAZSLdUQm66DigO7cDdi4G5jn5X39w5m9zu80PecltCksZvEGpSRgs-_fU1_FY8_rVKP8RaEVFUreXa7V5-LvGHe30m5QwjbBqmhq-isnGI6n2lKGrahI-SU3mwJh8CYxkKsHQdto1htYs68-mzOYQxOZtpdj4b0uK-Zx5_KetdseOA`;

  try {
    const result = await fetch(url);
    return await result.json();
  } catch (error) {
    console.log(error);
  }
}
