import React from "react";

import "./TermsAndLegacies.css";
import { withTranslation } from "react-i18next";
class TermsAndLegacies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div className="TermsAndLegacies">
        <header className="header">
          <div className="navbar navbar-default navbar-fixed-top">
            <div className="container">
              <div className="row">
                <div className="navbar-header page-scroll">
                  <button
                    type="button"
                    className="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#myNavbar"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  <div style={{ flex: 1, display: "flex" }}>
                    <a className="navbar-brand page-scroll" href="/">
                      <h4 className="title">
                        <img
                          width="60"
                          height="60"
                          src="/padlovelogonbg.png"
                          alt="logo"
                          style={{    marginTop: "-10px"}}
                        />
                        <span style={{ marginLeft: 10 }}>
                          {t("termsofuse")}
                        </span>
                      </h4>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container">
          <h4>{t("welcome")}</h4>
          <p>{t("introduction.paragraph1")}</p>
          <p>{t("introduction.paragraph2")}</p>
          <p>{t("introduction.paragraph3")}</p>
          <p>{t("introduction.paragraph4")}</p>
          <h3>{t("tableofcontents")}</h3>

          <ol>
            <li>
              <a href="#accounts">{t("accountstitle")}</a>
            </li>
            <li>
              <a href="#services">{t("servicestitle")}</a>
            </li>
            <li>
              <a href="#livingtogether">{t("livingtogethertitle")}</a>
            </li>
            <li>
              <a href="#abouttechnologyprotectionequipment">
                {t("abouttechnologyprotectionequipment")}
              </a>
            </li>
            <li>
              <a href="#HOWDOWEFINANCEOURSERVICES">
                {t("HOWDOWEFINANCEOURSERVICES")}
              </a>
            </li>
            <li>
              <a href="#THECOMMITMENTOFTHEUSERS">
                {t("THECOMMITMENTOFTHEUSERS")}
              </a>
            </li>
            <li>
              <a href="#WHATCANYOUSHAREONPADLOVE">
                {t("WHATCANYOUSHAREONPADLOVE")}
              </a>
            </li>
            <li>
              <a href="#AUTHORISATIONSTHATYOUGIVETOUS">
                {t("AUTHORISATIONSTHATYOUGIVETOUS")}
              </a>
            </li>
            <li>
              <a href="#ADDITIONEALMEASURES">{t("ADDITIONEALMEASURES")}</a>
            </li>
          </ol>
          <div id="accounts">
            <h4>{t("accountstitle")}</h4>
            <p>{t("accounts.paragraph1")}</p>
            <p>{t("accounts.paragraph2")}</p>
            <p>{t("accounts.paragraph3")}</p>
            <p>{t("accounts.paragraph4")}</p>
          </div>
          <div id="services">
            <h4>{t("servicestitle")}</h4>
            <p>{t("services.paragraph1")}</p>
            <h5>{t("servicestitle2_1")}</h5>
            <p>{t("servicestitle2_1.paragraph1")}</p>
            <h5>{t("servicestitle2_2")}</h5>
            <p>{t("servicestitle2_2.paragraph1")}</p>
            <h5>{t("servicestitle2_3")}</h5>
            <p>{t("servicestitle2_3.paragraph1")}</p>
          </div>
          <div id="livingtogether">
            <h4>{t("livingtogethertitle")}</h4>
            <p>{t("livingtogether.paragraph1")}</p>
          </div>
          <div id="abouttechnologyprotectionequipment">
            <h4>{t("abouttechnologyprotectionequipment")}</h4>
            <p>{t("abouttechnologyprotectionequipment.paragraph1")}</p>
          </div>
          <div id="HOWDOWEFINANCEOURSERVICES">
            <h4>{t("HOWDOWEFINANCEOURSERVICES")}</h4>
            <p>{t("HOWDOWEFINANCEOURSERVICES.paragraph1")}</p>
          </div>
          <div id="THECOMMITMENTOFTHEUSERS">
            <h4>{t("THECOMMITMENTOFTHEUSERS")}</h4>
            <p>
              {t("THECOMMITMENTOFTHEUSERS.paragraph1")}
              <ul>
                <li>
                  {t("THECOMMITMENTOFTHEUSERS.theusers")}
                  <ul style={{ marginLeft: 20 }}>
                    <li>{t("THECOMMITMENTOFTHEUSERS.theusers.1")}</li>
                    <li>{t("THECOMMITMENTOFTHEUSERS.theusers.2")}</li>
                    <li>{t("THECOMMITMENTOFTHEUSERS.theusers.3")}</li>
                    <li>{t("THECOMMITMENTOFTHEUSERS.theusers.4")}</li>
                    <li>{t("THECOMMITMENTOFTHEUSERS.theusers.5")}</li>
                  </ul>
                </li>
                <li>
                  {t("THECOMMITMENTOFTHEUSERS.ourpolicy")}
                  <ul style={{ marginLeft: 20 }}>
                    <li>{t("THECOMMITMENTOFTHEUSERS.ourpolicy.1")}</li>
                    <li>{t("THECOMMITMENTOFTHEUSERS.ourpolicy.2")}</li>
                    <li>{t("THECOMMITMENTOFTHEUSERS.ourpolicy.3")}</li>
                    <li>{t("THECOMMITMENTOFTHEUSERS.ourpolicy.4")}</li>
                  </ul>
                </li>
              </ul>
            </p>
          </div>
          <div id="WHATCANYOUSHAREONPADLOVE">
            <h4>{t("WHATCANYOUSHAREONPADLOVE")}</h4>
            <p>{t("WHATCANYOUSHAREONPADLOVE.paragraph1")}</p>
          </div>
          <div id="AUTHORISATIONSTHATYOUGIVETOUS">
            <h4>{t("AUTHORISATIONSTHATYOUGIVETOUS")}</h4>
            <p>
              <ul>
                <li>
                  {t("AUTHORISATIONSTHATYOUGIVETOUS.fromyoutoyou")}
                  <ul style={{ marginLeft: 20 }}>
                    <li>
                      {t("AUTHORISATIONSTHATYOUGIVETOUS.fromyoutoyou.1")}
                      <ul style={{ marginLeft: 20 }}>
                        <li>
                          {t("AUTHORISATIONSTHATYOUGIVETOUS.fromyoutoyou.1.1")}
                        </li>
                        <li>
                          {t("AUTHORISATIONSTHATYOUGIVETOUS.fromyoutoyou.1.2")}
                        </li>
                        <li>
                          {t("AUTHORISATIONSTHATYOUGIVETOUS.fromyoutoyou.1.3")}
                        </li>
                        <li>
                          {t("AUTHORISATIONSTHATYOUGIVETOUS.fromyoutoyou.1.4")}
                        </li>
                        <li>
                          {t("AUTHORISATIONSTHATYOUGIVETOUS.fromyoutoyou.1.5")}
                        </li>
                        <li>
                          {t("AUTHORISATIONSTHATYOUGIVETOUS.fromyoutoyou.1.6")}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>{t("AUTHORISATIONSTHATYOUGIVETOUS.useyourprofile")}</li>
                <li>{t("AUTHORISATIONSTHATYOUGIVETOUS.updateapp")}</li>
                <li>{t("AUTHORISATIONSTHATYOUGIVETOUS.limitations")}</li>
              </ul>
            </p>
          </div>
          <div id="ADDITIONEALMEASURES">
            <h4>{t("ADDITIONEALMEASURES")}</h4>
            <p>{t("ADDITIONEALMEASURES.updateourcondition")}</p>
            <p>
              {t(
                "ADDITIONEALMEASURES.constantlyworktoupgradeourservices.paragraph1"
              )}
            </p>
            <p>
              <ul>
                <li>
                  {t(
                    "ADDITIONEALMEASURES.constantlyworktoupgradeourservices.1"
                  )}
                </li>
                <li>
                  {t(
                    "ADDITIONEALMEASURES.constantlyworktoupgradeourservices.2"
                  )}
                </li>
                <li>
                  {t(
                    "ADDITIONEALMEASURES.constantlyworktoupgradeourservices.3"
                  )}
                </li>
              </ul>
            </p>
            <p>
              {t(
                "ADDITIONEALMEASURES.constantlyworktoupgradeourservices.paragraph2"
              )}
            </p>
            <p>{t("ADDITIONEALMEASURES.suspensionordeletingofanaccount")}</p>
            <p>
              {t(
                "ADDITIONEALMEASURES.suspensionordeletingofanaccount.paragraph1"
              )}
            </p>
            <p>{t("ADDITIONEALMEASURES.limitationofresponsibility")}</p>
            <p>
              {t("ADDITIONEALMEASURES.limitationofresponsibility.paragraph1")}
            </p>
            <p>{t("ADDITIONEALMEASURES.litigation")}</p>
            <p>{t("ADDITIONEALMEASURES.litigation.paragraph1")}</p>
            <p>{t("ADDITIONEALMEASURES.supplements")}</p>
            <p>
              <ul>
                <li>{t("ADDITIONEALMEASURES.supplements.1")}</li>
                <li>{t("ADDITIONEALMEASURES.supplements.2")}</li>
                <li>{t("ADDITIONEALMEASURES.supplements.3")}</li>
                <li>{t("ADDITIONEALMEASURES.supplements.4")}</li>
                <li>{t("ADDITIONEALMEASURES.supplements.5")}</li>
                <li>{t("ADDITIONEALMEASURES.supplements.6")}</li>
                <li>{t("ADDITIONEALMEASURES.supplements.7")}</li>
                <li>{t("ADDITIONEALMEASURES.supplements.8")}</li>
                <li>{t("ADDITIONEALMEASURES.supplements.9")}</li>
                <li>{t("ADDITIONEALMEASURES.supplements.10")}</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(TermsAndLegacies);
